html {
  --theme-color: #fa388d;
  --button-primary-background: linear-gradient(180deg, #ffd2e7 1.39%, #ff86bd 30.56%, #fa388d 69.1%, #d82b77 101.39%);
  --page-category-sidebar-active-background: #fa388d;
  --page-category-region-active-color: linear-gradient(
    180deg,
    #ffd2e7 1.39%,
    #ff86bd 30.56%,
    #fa388d 69.1%,
    #d82b77 101.39%
  );
  --page-sectaglist-active-color: linear-gradient(
    180deg,
    #ffd2e7 1.39%,
    #ff86bd 30.56%,
    #fa388d 69.1%,
    #d82b77 101.39%
  );
  --view-short-promotion-hint-background: linear-gradient(
    180deg,
    #ffd2e7 1.39%,
    #ff86bd 30.56%,
    #fa388d 69.1%,
    #d82b77 101.39%
  );

  --page-game--select-button-color: #2e2e2e;
  --page-game--select-button-active-color: #fa388d;
  --page-profile--button-icon-color: linear-gradient(180deg, #FFD2E7 0%, #FF86BD 29.17%, #FA388D 67.71%, #D82B77 100%);
  --page-profile--name-box-color: white;
  --page-profile--decoration-background-height: 3.15rem;

  --page-system-config--idcard-button-background: linear-gradient(
    180deg,
    #ffd2e7 1.39%,
    #ff86bd 30.56%,
    #fa388d 69.1%,
    #d82b77 101.39%
  );

  --page-share-background: linear-gradient(180deg, #FFD2E7 1.39%, #FF86BD 30.56%, #FA388D 69.1%, #D82B77 101.39%);
  --page-share-link-color: #FA388D;

  --component-area-title--dec-color: linear-gradient(
    180deg,
    #ffd2e7 1.39%,
    #ff86bd 30.56%,
    #fa388d 69.1%,
    #d82b77 101.39%
  );
  --component-tabs--active-color: linear-gradient(
    180deg,
    #ffd2e7 1.39%,
    #ff86bd 30.56%,
    #fa388d 69.1%,
    #d82b77 101.39%
  );
}